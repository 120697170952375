<template>
    <div class="main-content">
        <div class="row w-100">
            <div class="col-12 col-md-3">
                <b-card class="card-designs">
                    <div class="row d-flex justify-content-between align-items-center">
                        <div>
                            <b-card-body class="py-0 pl-1">
                                <h2>
                                    <strong>
                                        {{ affilation.total_earned }}
                                    </strong>
                                </h2>
                            </b-card-body>
                            <b-card-header class="py-0 mt-1 pl-1">
                                <b-card-title class="text-uppercase">
                                    {{ $t('Total Earned') }}
                                </b-card-title>
                            </b-card-header>
                        </div>
                        <div>
                            <b-img fluid class="pr-1" :src="`${appImageUrlPrefix}/icons/withdraw_request.png`" />
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-md-3">
                <b-card class="card-designs">
                    <div class="row d-flex justify-content-between align-items-center">
                        <div>
                            <b-card-body class="py-0 pl-1">
                                <h2>
                                    <strong>
                                        {{ affilation.total_balance }}
                                    </strong>
                                </h2>
                            </b-card-body>
                            <b-card-header class="py-0 mt-1 pl-1">
                                <b-card-title class="text-uppercase">
                                    {{ $t('Available Balance') }}
                                </b-card-title>
                            </b-card-header>
                        </div>
                        <div>
                            <b-img fluid class="pr-1" :src="`${appImageUrlPrefix}/icons/balance.png`" />
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-md-3">
                <b-card class="card-designs">
                    <div class="row d-flex justify-content-between align-items-center">
                        <div>
                            <b-card-body class="py-0 pl-1">
                                <h2>
                                    <strong>
                                        {{ affilation.signups }}
                                    </strong>
                                </h2>
                            </b-card-body>
                            <b-card-header class="py-0 mt-1 pl-1">
                                <b-card-title class="text-uppercase">
                                    {{ $t('Total Signups') }}
                                </b-card-title>
                            </b-card-header>
                        </div>
                        <div>
                            <b-img fluid class="pr-1" :src="`${appImageUrlPrefix}/icons/sign-up.png`" />
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-md-3">
                <b-card class="card-designs">
                    <div class="row d-flex justify-content-between align-items-center">
                        <div>
                            <b-card-body class="py-0 pl-1">
                                <h2>
                                    <strong>
                                        {{ affilation.subscription }}
                                    </strong>
                                </h2>
                            </b-card-body>
                            <b-card-header class="py-0 mt-1 pl-1">
                                <b-card-title class="text-uppercase">
                                    {{ $t('Total Subscription') }}
                                </b-card-title>
                            </b-card-header>
                        </div>
                        <div>
                            <b-img fluid class="pr-1" :src="`${appImageUrlPrefix}/icons/subscription.png`" />
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
        <b-card v-if="!isEmpty(affilation.referral_code)" >
                <div class="row">
                    <div class="col-12 col-md-6">
                        <p>
                            <strong>
                                {{ $t('How to use Referral Program') }}
                            </strong>
                        </p>
                        <div>
                            <ol start="1" class="pl-0 ml-1 mt-2">
                                <li>{{ $t('Copy referral link') }}</li>
                                <li>{{ $t('Share with your friends and others') }}</li>
                                <li>{{ $t('Make money on their subscriptions') }}</li>
                            </ol>
                        </div>
                        <div>
                            <p class="mb-0">
                                {{ $t('Our affiliate program commission rate for subscriptions is') }} 
                            </p>
                            <div>For {{ $t('AI Subscriber: You get') }} {{ affilation.commission.user_referral }}% commission. {{ $t('Your referral gets') }} {{ affilation.commission.for_user_referred }}% discount with your referral code.</div>
                            <div>For {{ $t('Store: You get') }} {{ affilation.commission.store_referral }}% commission. {{ $t('Your referral gets') }} {{ affilation.commission.for_store_referred }}% discount with your referral code.</div>
                            <div>For {{ $t('Provider: You get') }} {{ affilation.commission.provider_referral }}% commission. {{ $t('Your referral gets') }} {{ affilation.commission.for_provider_referred }}% discount with your referral code.</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-md-0 mt-4">
                        <p>
                            <strong>
                                {{ $t('Your Referral Link') }}
                            </strong>
                        </p>
                        <p>
                            {{ $t('Invite your friends and others and earn commissions from their subscriptions') }}
                        </p>

                        <label class="mt-1 mb-0">{{ $t('Referral URL') }}</label>
                        <b-input-group class="input-group-merge">
                            <b-form-input
                                :value="`${appUrl}/referral/${affilation.referral_code}`"
                                class="form-control-merge"
                                placeholder="Store Url"
                                readonly
                            />
                            <b-input-group-append
                                style="border: 1px solid #d8d6de; border-top-right-radius: 6px; border-bottom-right-radius: 6px; border-left: 2px solid #d8d6de;"
                                @click="copy(`${appUrl}/referral/${affilation.referral_code}`)"
                            >
                            <b-img
                                width="35"
                                height="35"
                                style="padding: 5px;"
                                :src="require('@/assets/images/app-icons/copy_new.png')"
                            />
                            </b-input-group-append>
                        </b-input-group>

                        <label class="mt-2 mb-0">{{ $t('Referral Code') }}</label>
                        <b-input-group class="input-group-merge">
                            <b-form-input
                                :value="`${affilation.referral_code}`"
                                class="form-control-merge"
                                placeholder="Store Url"
                                readonly
                            />
                            <b-input-group-append
                                style="border: 1px solid #d8d6de; border-top-right-radius: 6px; border-bottom-right-radius: 6px; border-left: 2px solid #d8d6de;"
                                @click="copy(affilation.referral_code)"
                            >
                            <b-img
                                width="35"
                                height="35"
                                style="padding: 5px;"
                                :src="require('@/assets/images/app-icons/copy_new.png')"
                            />
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
        </b-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import { showDangerNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app';
export default {
    name: "AffilationOverview",
    data: () => ({
        appUrl: process.env.VUE_APP_FRONT_END_BASE_URL,
        appImageUrlPrefix: `${process.env.VUE_APP_API_BASE_END_POINT}/images`
    }),
    computed: {
        ...mapGetters('affilation', [
            'affilation'
        ]),
    },
    methods: {
        isEmpty,
        ...mapActions('affilation', [
            'getAffilation'
        ]),
        copy(data) {
            if (window.SuperApp.helper.copyUrl(data)) {
                showSuccessNotification(this, 'Copied successfully')
            } else {
                showDangerNotification(this, 'Failed to copy')
            }
        },
    },

    mounted: async function () {
        this.getAffilation()
    }
}
</script>